'use client';

import { CacheProvider } from '@chakra-ui/next-js';
import { CSSReset, ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import FilterContext from '@/components/FilterContext';
import { extendTheme } from '@chakra-ui/react'
import { useState,useEffect } from 'react';
import { useRouter } from 'next/router';
import { usePathname } from 'next/navigation';
//import ChatBot from '@/components/ChatBot';


export function Providers({ children }) {
  const [filtersContext, setFiltersContext] = useState(null)



  /*function ConditionalChatBot() {
    const pathname = usePathname();
    const isRootOrSingleLevel = /^\/([^\/]+)?$/.test(pathname);
  
    return isRootOrSingleLevel ? <ChatBot /> : null;
  }
*/
 
const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  breakpoints: {
    sm: '30em',    // 480px
    customSmMd: '37.5em', // 600px (nuevo)
    md: '48em',    // 768px
    lg: '62em',    // 992px
    xl: '80em',    // 1280px
    '2xl': '96em', // 1536px
  },
  styles: {
    global: {
      body: {
        color: 'black',
        bg: 'white',
      },
    },
  },
});
  return (

    <FilterContext.Provider value={[filtersContext, setFiltersContext]}>
    <CacheProvider>
      <ChakraProvider theme={theme}>
      <CSSReset />
        <ColorModeScript
        initialColorMode='light'/>
        {children}</ChakraProvider>
    
    </CacheProvider>
    </ FilterContext.Provider>


  );
}
